import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ContactPage = () => (
    <Layout style="style2">
        <SEO title="Contact" />

        <header className="small">
            <h2>Get In Touch</h2>
            <p>Fill out the form below...</p>
        </header>

        <div className="inner special medium">
            <form action="/contact" method="post">
                <div className="field half">
                    <input
                        name="name"
                        id="name"
                        placeholder="Name"
                        type="text"
                    />
                </div>
                <div className="field half">
                    <input
                        name="email"
                        id="email"
                        placeholder="Email"
                        type="email"
                    />
                </div>
                <div className="field">
                    <textarea
                        name="message"
                        id="message"
                        rows={8}
                        placeholder="Message"
                    ></textarea>
                </div>
                <div className="field"></div>
                <ul className="actions">
                    <li>
                        <input
                            value="Send Message"
                            className="button next"
                            type="submit"
                        />
                    </li>
                </ul>
            </form>
        </div>
    </Layout>
);

export default ContactPage;
